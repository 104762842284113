
import { Component } from "vue-property-decorator";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import { IpAddress } from "@/config/Modules";
import IpAddressForm from "@/views/BaseData/IpAddress/IpAddressForm.vue";
import { mixins } from "vue-class-component";
import { IpAddressStoreMixin } from "@/mixins/Stores//IpAddressStoreMixin.ts";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ICreateMixin from "@/mixins/interface/ICreateMixin";

@Component({
  components: { IpAddressForm },
  mixins: [CreateMixin, IpAddressStoreMixin],
})
export default class IpAddressCreate
  extends mixins(IpAddressStoreMixin, CreateMixin)
  implements ICreateMixin
{
  protected name = "IpAddressCreate";

  public get resource(): string {
    return IpAddress.resource;
  }

  public get descriptionField(): string {
    return IpAddress.description_field;
  }

  public createItemAction(options: ICrudOptions): any {
    return this.createIpAddressItemAction(options);
  }

  public redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "BaseDataIpAddressUpdate",
      params: { id: responseData.id },
    });
  }
}
